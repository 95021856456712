<template>
  <router-link :to="{ name: 'playlistShow', params: {id: playlist.id_playlist} }">
    <div class="embed-responsive embed-responsive-16by9">
      <img
        :src="playlist.avatar"
        :alt="`Playlist ${playlist.nome}`"
        class="embed-responsive-item"
      >
    </div>
    <div class="info-box mt-2">
      <span class="playlist-name mb-2">{{ playlist.nome }}</span>
      <span class="artists-name">{{ playlist.artist_names }}</span>
    </div>
  </router-link>
</template>

<script>
  export default {
    props: {
      playlist: {
        default: () => {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-box {
    color: #fff;

    .playlist-name {
      display: block;
      font-size: 19px;
      line-height: 21px;
    }

    .artists-name {
      display: block;
      font-size: 13px;
      line-height: 15px;
      color: rgba(#fff, .6);
    }
  }
</style>