<template>
  <div
    class="page-with-title"
  >
    <page-title>
      <h1>Playlists</h1>
    </page-title>
    <div class="p-4 mt-4">
      <div class="row">
        <div
          v-for="playlist in playlists"
          :key="playlist.id_playlist"
          class="col-6 col-md-3 col-lg-2 mb-4"
        >
          <PlaylistInfoBox
            :playlist="playlist"
          />
        </div>
      </div>
      <div class="text-center">
        <button-next-page
          v-if="page != lastPage"
          @reached="page++; fetchPlaylists()"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import PlaylistInfoBox from '@/components/playlists/PlaylistInfoBox'
  import ButtonNextPage from '@/components/utils/ButtonNextPage'
  import { getPlaylists } from '@/api/playlists'

  export default {
    components: {
      PageTitle,
      ButtonNextPage,
      PlaylistInfoBox
    },
    data: () => ({
      playlists: [],
      page: 1,
      lastPage: null
    }),
    created() {
      this.fetchPlaylists()
    },
    methods: {
      async fetchPlaylists() {
        const res = await getPlaylists({
          page: this.page
        })
        this.lastPage = res.last_page
        res.data.map(playlist => this.playlists.push(playlist))
      }
    }
  }
</script>