import request from '@/utils/request'

export async function getPlaylists(params) {
  const res = await request({
    url: '/playlists',      
    method: 'get',
    params
  })
  return res.data.data
}

export async function getPlaylist(id) {
  const res = await request({
    url: `/playlists/${id}`,
    method: 'get'
  })

  return res.data.data
}

export async function getPlaylistSongs(id) {
  const res = await request({
    url: `/playlists/${id}`,
    method: 'get'
  })

  return res.data.data
}

export async function storePlaylist(playlist) {
  const form = new FormData()
  for (let i in playlist) form.append(i, playlist[i])

  const res = await request({
    url: '/playlists',
    method: 'post',
    data: form
  })

  return res.data.data
}

export async function updatePlaylist(playlist) {
  const form = new FormData()
  for (let i in playlist) form.append(i, playlist[i])
  form.append('_method', 'put')

  const res = await request({
    url: `/playlists/${playlist.id_playlist}`,
    method: 'post',
    data: form
  })

  return res.data.data
}

export async function storePlaylistSongs(playlistId, songs) {
  await request({
    url: `/playlists/${playlistId}/songs`,
    method: 'post',
    data: {musicas: songs}
  })
}

export async function updatePlaylistSongs(playlistId, songs, songsRemoved) {
  await request({
    url: `/playlists/${playlistId}/songs`,
    method: 'post',
    data: {
      '_method': 'put',
      musicas: songs,
      musicas_removidas: songsRemoved
    }
  })
}

export async function deletePlaylist (id) {
  await request({
    url: `/playlists/${id}`,
    method: 'delete'
  })
}